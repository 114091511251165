/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem;
  /*========== Colors ==========*/
  --first-color: #4b4ea9;
  --first-color-alt: #3b3e81;
  --black-color: hsl(206, 12%, 12%);
  --card-color: hsl(0, 0%, 90%);
  --title-color: hsl(206, 12%, 12%);
  --text-color: hsl(206, 4%, 42%);
  --text-color-light: hsl(206, 4%, 75%);
  --body-color: hsl(206, 4%, 97%);
  --container-color: #fff;

  /*========== Font and typography ==========*/
  --body-font: "Lato", sans-serif;
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
}

@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 3.5rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  font-weight: 400;
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-bold);
}

h1,
h2,
h3,
p {
  line-height: 140%;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.d-none {
  display: none;
}

.container {
  max-width: 1024px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.section {
  padding: 4.5rem 0 2rem;
}

.section__title {
  position: relative;
  font-size: var(--h1-font-size);
  margin-bottom: 2.5rem;
  font-weight: 700;
}

.section__title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 25%;
  height: 4px;
  background-color: var(--first-color);
}

@media (max-width: 1023.5px) {
  .section__title::before {
    left: 50%;
    transform: translateX(-50%);
  }
}

.header {
  width: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo,
.nav__toggle {
  color: #fff;
}

.nav__logo {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.nav__logo span {
  color: var(--first-color);
}

.nav__toggle {
  display: inline-flex;
  font-size: 1.15rem;
  cursor: pointer;
}

/* change header background */
.scroll-header {
  background-color: hsla(206, 12%, 12%, 0.9);
  box-shadow: 0 1px 4px hsla(0, 4%, 15%, 0.1);
}

@media screen and (max-width: 768px) {
  .nav__menu {
    position: fixed;
    background-color: var(--black-color);
    top: 0;
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    transition: 0.3s;
    z-index: 999;
  }
}

.nav__list {
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 3rem;
  padding-top: 9rem;
}

.nav__link {
  text-transform: uppercase;
  color: var(--text-color-light);
  font-size: var(--h2-font-size);
  transition: 0.3s;
  font-weight: 700;
}

.nav__link.active-link {
  color: #fff;
}

.nav__link:hover {
  color: #fff;
}

.nav__close {
  font-size: 2rem;
  color: #fff;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

/* Show menu */
.show-menu {
  right: 0;
}

/* hero style */
.hero {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  /* z-index: 1; */
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.hero__title,
.hero__button {
  z-index: 2;
}

.hero__title {
  text-align: center;
  color: #fff;
  font-size: var(--h1-font-size);
  z-index: 99;
  margin-bottom: 2rem;
}

.hero__button {
  background-color: var(--first-color);
  padding: 1rem 1.75rem;
  border-radius: 0.25rem;
  color: #fff;
  text-transform: uppercase;
  transition: 0.3s;
}

.hero__button:hover {
  background-color: var(--first-color-alt);
}
/* end */

/* special style */
.special__category {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 200px;
  justify-content: center;
  row-gap: 2.5rem;
}

.special__img__border {
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
  transition: 0.4s;
  border: 3px solid var(--first-color);
  border-radius: 100%;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.special__title {
  font-size: var(--h2-font-size);
  margin-bottom: 0.25rem;
  font-weight: 700;
}
/* end */

/* products style */
.products__filters {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.products__item {
  cursor: pointer;
}

.products__title {
  font-size: var(--h3-font-size);
  color: var(--text-color);
  font-weight: 700;
  border-radius: 12px;
  padding: 1rem 2rem;
  border: var(--first-color) 2px solid;
  color: var(--first-color);
}
.products__title.active {
  background: var(--first-color);
  color: #fff !important;
}

.active-product .products__title {
  color: var(--title-color);
}

.products__stock {
  font-size: var(--small-font-size);
}

.products__content {
  /* grid-template-columns: 200px; */
  justify-content: center;
}

.products__card {
  background-color: var(--container-color);
  border-radius: 0.75rem;
}

.products__img {
  border-radius: 0.75rem 0.75rem 0 0;
}

.products__data {
  padding: 1rem;
  position: relative;
}

.products__price {
  margin-top: 10px;
  font-size: var(--h2-font-size);
  font-weight: 700;
}

.products__name {
  font-size: var(--h3-font-size);
  font-weight: 700;
  margin-bottom: 10px;
}

.products__button {
  border-radius: 50%;
  padding: 0.625rem;
  display: inline-flex;
  font-size: 1.25rem;
  position: absolute;
  right: 1rem;
  top: -1.25rem;
  box-shadow: 0 4px 12px hsla(0, 88%, 62%, 0.2);
  background-color: var(--first-color);
  color: #fff;
}
/* end */

/* place style */
.place {
  background-color: #fff;
}

.place-content {
  row-gap: 2.5rem;
}

.place__images {
  position: relative;
  justify-self: center;
}

.place__img-big,
.place__img-small {
  border-radius: 0.5rem;
}

.place__img-big {
  width: 280px;
}

.place__img-smalls {
  width: 80px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  row-gap: 0.75rem;
}

.place__img-small.active {
  border: 3px solid var(--first-color);
}

.place__data {
  text-align: center;
}

.place__title {
  font-size: var(--h1-font-size);
  font-weight: 700;
}

.place__title {
  margin-bottom: 0.25rem;
}
.place__description {
  margin-bottom: 2.5rem;
}
/* end */

/* blog */
.blog__container {
  padding-bottom: 2rem;
}

.blog__content {
  row-gap: 3rem;
}

.blog__image {
  position: relative;
  margin-bottom: 1.5rem;
}

.blog__img {
  border-radius: 0.5rem;
}

.blog__button {
  display: inline-flex;
  background-color: #fff;
  padding: 1.15rem;
  border-radius: 100%;
  font-size: 2rem;
  color: #fff;
  position: absolute;
  right: 1rem;
  bottom: -1.5rem;
  background-color: var(--first-color);
}

.blog__button i {
  transform: rotate(-30deg);
  transition: 0.4s;
}

.blog__button:hover i {
  transform: rotate(-30deg) translateX(0.25rem);
}

.blog__title {
  font-size: var(--h2-font-size);
  margin-bottom: 0.75rem;
  font-weight: 700;
}

.blog__description {
  margin-bottom: 1.5rem;
}

.blog__stats,
.blog__reaction {
  display: flex;
  align-items: center;
}

.blog__stats {
  column-gap: 1.5rem;
  position: absolute;
  bottom: 0;
  background-color: hsla(0, 88%, 62%, 0.4);
  color: #fff;
  padding: 0.75rem;
  border-radius: 0 0.5rem 0 0.5rem;
  backdrop-filter: blur(30px);
}

.blog__reaction {
  column-gap: 0.25rem;
}

.blog__reaction i {
  font-size: 1.25rem;
}

.blog__reaction span {
  font-size: var(--small-font-size);
}
/* end */

/* footer style */
.footer {
  background-color: var(--black-color);
  padding: 4rem 0 2.5rem;
  padding-top: 6rem;
}

.footer__title {
  text-align: center;
  color: #fff;
  font-size: var(--biggest-font-size);
  margin-bottom: 10px;
}

.footer__description {
  margin-bottom: 2rem;
  text-align: center;
}

.footer__title span {
  color: var(--first-color);
}

.footer__newsletter-description {
  margin-bottom: 0.75rem;
}

.footer__newsletter {
  display: flex;
  column-gap: 0.5rem;
}

.footer__input {
  border: none;
  outline: none;
  padding: 1.25rem 1rem;
  border-radius: 0.25rem;
  width: 70%;
  color: var(--text-color);
}

.footer__button {
  padding: 0.75rem;
  font-size: 2rem;
  border-radius: 0.25rem;
  display: inline-flex;
  background-color: var(--first-color);
}

.footer__button i {
  transform: rotate(-30deg);
  transition: 0.4s;
  color: #fff;
}

.footer__button:hover i {
  transform: rotate(-30deg) translate(0.25rem);
}

.footer__content {
  row-gap: 2.5rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid var(--text-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer__subtitle {
  color: #fff;
  font-size: var(--h2-font-size);
  margin-bottom: 0.75rem;
}

.footer__flag {
  width: 1rem;
}

.footer__group {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 1.5rem;
}

.footer__social {
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.footer__social-link {
  color: #fff;
  transition: 0.4s;
}

.footer__social-link:hover {
  color: var(--first-color);
}

.footer__copy {
  font-size: var(--small-font-size);
}
/* end */

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {
  .container {
    margin-inline: 1rem;
  }

  .place__img-smalls {
    display: none;
  }
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .special__category {
    grid-template-columns: repeat(2, 200px);
    column-gap: 3rem;
  }

  .products__content {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 3rem;
    padding: 0 5rem;
  }

  .blog__content {
    grid-template-columns: 450px;
    justify-content: center;
  }

  .footer__newsletter {
    width: 350px;
  }
}
/* For large devices */
@media screen and (min-width: 769px) {
  .section {
    padding: 7rem 0 2rem;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
  }

  .nav__toggle,
  .nav__close {
    display: none;
  }

  .nav__list {
    padding-top: 0;
    flex-direction: initial;
    column-gap: 4rem;
  }
  .nav__link {
    position: relative;
    font-size: var(--normal-font-size);
    text-transform: initial;
  }

  .products__filters {
    grid-template-columns: repeat(3, 200px);
  }

  .place__content {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer__content {
    flex-direction: row;
  }

  .place__data {
    text-align: initial;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    margin-inline: auto;
  }

  .special__category {
    grid-template-columns: repeat(3, 250px);
    column-gap: 8rem;
  }

  .products__filters {
    /* margin: 4rem 0 5.5rem; */
    column-gap: 4rem;
  }

  .products__content {
    grid-template-columns: repeat(3, 1fr);
    justify-content: initial;
    column-gap: 5rem;
  }

  .products__name {
    font-size: var(--h3-font-size);
  }

  .products__button {
    font-size: 1.5rem;
    padding: 0.75rem;
    right: 1.5rem;
    top: -1.5rem;
  }

  .place__content {
    column-gap: 6rem;
  }

  .place__images {
    margin-top: 2rem;
  }

  .place__img-big {
    width: 428px;
  }

  .place__img-smalls {
    /* width: 153px; */
    right: -35px;
  }

  .place__title {
    margin-bottom: 1rem;
  }

  .blog__container {
    padding-bottom: 5rem;
  }

  .blog__content {
    grid-template-columns: repeat(2, 450px);
    column-gap: 5rem;
    padding-top: 2rem;
  }

  .footer__content {
    grid-template-columns: repeat(4, max-content);
    column-gap: 4rem;
    padding-bottom: 5.5rem;
  }

  .footer__group {
    margin-top: 3rem;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer__social {
    column-gap: 2rem;
  }

  .footer__social-link {
    font-size: 1.5rem;
  }
}

/* TEST */

.section-one {
  height: 100vh;
  position: relative;
  /* border: 2px solid red; */
}
#slide-box {
  /* border: 2px solid blueviolet; */
  visibility: visible;
  position: absolute;
  width: 100vw;
  opacity: 0;
}
#slide-box-text {
  position: absolute;
  width: 60vw;
  height: 50vh;
  right: 0;
  top: 100px;
  font-size: 7em;
  overflow: hidden;
  opacity: 0;
}

/* Base styles for the products grid */
.products__sub-category-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust the space between items */
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
}

.products__sub-category-title {
  font-size: 2rem; /* Adjust the size as needed */
  text-transform: uppercase; /* Capitalize all letters */
  position: relative;
  font-size: var(--h3-font-size);
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
  text-align: center;
}

.products__card {
  flex: 0 0 100%; /* Default to full width on mobile */
  max-width: 264px;
}

.section__menu {
  position: relative;
  font-size: var(--h1-font-size);
  margin-bottom: 2.5rem;
  font-weight: 700;
  text-align: center;
}

/* Tablet (at least 768px) */
@media (min-width: 768px) {
  .products__card {
    flex: 0 0 calc(50% - 20px); /* 2 columns with gap adjustment */
  }
}

/* Desktop (at least 1024px) */
@media (min-width: 1024px) {
  .products__card {
    flex: 0 0 calc(33.333% - 20px); /* 3 columns with gap adjustment */
  }
  .products__sub-category-title {
    text-align: left;
  }
  .section__menu {
    text-align: left;
  }
  .products__filters {
    justify-content: flex-start;
  }
  .products__sub-category-content {
    justify-content: space-between;
  }
}

/* .footer__container {
	margin: 0 2rem;
} */

.products__content .skeleton {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 3rem 1rem;
  flex-wrap: wrap;
  gap: 20px;
}
.products__card-skeleton {
  border-radius: 12px;
}
.skeleton .products__card {
  min-width: 263px;
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .products__content .skeleton {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .skeleton .products__card {
    min-width: 263px;
  }
}

/* For large devices */
@media screen and (min-width: 1024px) {
}

.checkout-button {
  display: flex;
  justify-self: center;
  width: 90vw;
  justify-content: space-between;
  align-items: center;
  border: 3px solid var(--first-color);
  border-radius: 10px;
  background-color: #fff;
  color: var(--first-color);
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  z-index: 5;
}

.checkout-button p {
  font-size: 25px;
}

.checkout-button button {
  background-color: var(--first-color);
  color: #fff;
  padding: 1rem 1.5rem;
  border-radius: 10px;
}

.checkout-button button.secondary-button {
  background-color: white;
  border: var(--first-color) 2px solid;
  color: var(--first-color);
  padding: 0.6rem 1rem 0.4rem 1rem;
  border-radius: 10px;
}

.checkout-section::-webkit-scrollbar {
  display: none !important;
}
.checkout-section {
  position: fixed;
  bottom: 100px;
  z-index: 5;
  background-color: #fff;
  width: 90vw;
  border: 3px solid var(--first-color);
  border-radius: 10px;
  padding: 1rem 1.5rem;
  max-height: calc(100vh - 130px);
  overflow-y: scroll;
}

.single-order-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.25rem 0;
}

.single-order-name {
  display: flex;
  justify-content: center;
  align-items: center;
}

body::-webkit-scrollbar {
  display: none !important;
}

.floating-whatsapp-icon {
  position: fixed !important;
  bottom: 5vw;
  right: 5vw;
  background-color: #25d366 !important;
  color: white !important;
  border: 2px solid #075e54 !important;
}

.about-img {
  object-fit: cover;
  border-radius: 12px;
  aspect-ratio: 1/1;
  min-width: 100px;
}
/* 

*/
.about-swiper {
  padding: 5vw !important;
}
.about-swiper .swiper-slide {
  border-radius: 12px;
}

.swiper-slide-shadow {
  background: none !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.place-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.place-images {
  max-width: 75%;
}

.place-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  min-height: 50%;
  justify-content: center;
  text-align: center;
}

.place__title {
  text-align: center;
}

@media (min-width: 769px) {
  .place-container {
    flex-direction: row;
    justify-content: space-around;
    max-width: 100%;
  }

  .place-images {
    max-width: 50%;
  }

  .place__title {
    text-align: left;
  }

  .place-description {
    flex-direction: column;
    justify-content: center;
    max-width: 50%;
    min-height: 100%;
    justify-content: center;
    text-align: left;
  }
}

.special__box {
  transform: translate(-200vw, 0px);
}

.special__category {
  transform: translate(-200vw, 0px);
}

.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: var(--first-color);
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
